import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Button } from 'react-bootstrap';

const BCMGuidesBestGearSets: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Best Gear Sets</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_bestsets.png"
            alt="Best gear sets"
          />
        </div>
        <div className="page-details">
          <h1>Best Gear Sets</h1>
          <h2>
            What gear sets to use on your characters in Black Clover Mobile!
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Best Gear Sets" />
        <p>
          Gearing in Black Clover Mobile isn't that hard - most characters
          prefer to use the same Gear Sets and what's best you can get the most
          popular sets in the Red Dungeon. That's one of the reasons why we
          suggest building the Blue team as a priority when you start playing
          the game.
        </p>
        <p>
          What is important though is that you{' '}
          <strong>
            nearly always want to use only 2-piece set bonuses because the Main
            Stats are super important
          </strong>
          . And if you want to achieve the 4-piece bonus, you're forced to use
          pieces with sub-optimal Main Stats, like DEF or HP for your offensive
          characters.
        </p>
        <p>
          In the sheet linked below you will find both the suggested 2-piece
          sets to use and also the main stats pieces you should use (for now
          only for SSR characters and some important SR ones - we will update
          the guide soon for every character available in the game). Also, we
          will only focus on the Yellow tier sets as you won't use the Basic
          ones in the long-run and they're placeholder gear.
        </p>
        {/* <Table striped bordered responsive className="gear-sets-bcm">
          <thead>
            <tr>
              <th>Character</th>
              <th>Set #1</th>
              <th>Set #1 - Main Stats</th>
              <th>Set #2</th>
              <th>Set #2 - Main Stats</th>
              <th>Set #3</th>
              <th>Set #3 - Main Stats</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="character">
                  <BCMCharacter
                    mode="icon"
                    slug="asta-clover-academy"
                    enablePopover
                    showLabel
                  />
                </div>
              </td>
              <td>
                <div className="gear-tips">
                  <StaticImage
                    src="../../../images/blackclover/sets/gold_atk.png"
                    alt="Atk Set"
                  />
                  <span>ATK</span>
                </div>
              </td>
            </tr>
          </tbody>
        </Table> */}
        <Button
          variant="primary"
          href="https://docs.google.com/spreadsheets/d/1cDrnWPhkSHgWfnDiO578xU_v2Upm8TpiZuBSWzxlljE/edit#gid=0"
          target="_blank"
          rel="noreferrer"
        >
          Black Clover Mobile Gear Tips sheet
        </Button>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesBestGearSets;

export const Head: React.FC = () => (
  <Seo
    title="Best Gear Sets | Black Clover M | Prydwen Institute"
    description="What gear sets to use on your characters in Black Clover Mobile!"
    game="bcm"
  />
);
